<template>
    <div>
        <div class="login-main">
            <div class="login-content">
                <div class="login-header">菜大王司机管理</div>
                <el-form label-width="70px" size="small" ref="loginForm" :model="loginData" style="margin-top: 18px;" :rules="formRules">
                    <el-form-item label="账户名" prop="username">
                        <el-input v-model="loginData.username" placeholder="请输入账户名"></el-input>
                    </el-form-item>

                    <el-form-item label="密码" prop="password">
                        <el-input
                            v-model="loginData.password"
                            type="password"
                            placeholder="请输入密码"
                            @keyup.enter.native="loginIn"
                        />
                    </el-form-item>
                    <el-form-item label="验证码" prop="code" class="form-item-code">
                        <div style="display:flex;">
                            <el-input v-model="loginData.code" type="text" placeholder="验证码" @keyup.enter.native="loginIn"/>
                            <el-image :src="codeImg" @click="getCode" style="width: 160px;height: 32px;margin-left: 5px;"/>
                        </div>
                    </el-form-item>
                </el-form>
                <el-button class="login-btn" type="primary" size="mini" @click="loginIn" :loading="loading">登录</el-button>
                <p style="font-size: 13px;margin-top: 3vh;color: #999999;">Tips：随便输入密码。。。</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data(){
            const passwordRule = (rule, val, callback)=>{
                if(!val){
                    callback(new Error("请输入账户密码"));
                }
                if(val.length < 6){
                    callback(new Error("最低密码长度6"))
                }
                callback();
            }
            const usernameRule = (rule, val, callback) => {
                if(!val){
                    callback(new Error("请输入账户名"))
                }
                callback();
            }
            const codeRule = (rule, val, callback) => {
                if(!val){
                    callback(new Error("请输入验证码"))
                }
                callback();
            }
            return {
                loginData: {
                    username: '',
                    password: '',
                    code: '',
                    type: 2,
                    guid: undefined
                },
                formRules: {
                    username: [{validator: usernameRule, trigger: 'blur'}],
                    password: [{validator: passwordRule, trigger: 'blur'}],
                    code:  [{ validator : codeRule, trigger: 'blur' }]
                },
                loading: false,
                redirect: undefined,
                otherQuery: undefined,
                codeImg: undefined
            }
        },
        watch: {
            $route: {
                handler: function(route) {
                    const query = route.query
                    if (query) {
                        this.redirect = query.redirect
                        this.otherQuery = this.getOtherQuery(query)
                    }
                },
                immediate: true
            }
        },
        mounted() {
            this.getCode();
        },
        methods: {
            loginIn: function () {
                if(this.loading) return;
                this.$refs['loginForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        this.$store.dispatch('user/login', this.loginData).then(()=>{
                            //this.loading = false;
                            this.$router.push({ path: this.redirect || '/', query: this.otherQuery }).catch(() =>{})
                        }).catch(()=>{
                            this.getCode();
                            this.loading = false;
                        })
                    } else {
                        console.log("error submit!!")
                        return false;
                    }
                });
            },
            getOtherQuery(query) {
                return Object.keys(query).reduce((acc, cur) => {
                    if (cur !== 'redirect') {
                        acc[cur] = query[cur]
                    }
                    return acc
                }, {})
            },
            getCode: function () {
                this.$request({
                    url: '/auth/getCode',
                    method: "GET"
                }).then(({data}) => {
                    this.loginData.guid = data.guid;
                    this.codeImg = data.base64;
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .login-main {
        width: 100%;
        height: 100vh;
        background-image:  url("~@/assets/loginbg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center 0;
        .login-header{
            font-size: 24px;
            font-weight: bolder;
            text-align: center;
        }
        .login-content {
            width: 300px;
            height: auto;
            margin: auto;
            border-radius: 5px;
            background-color: #fff;
            top: 25vh;
            padding: 4vh;
            position: absolute;
            left: 0;
            right: 0;

        }
        .login-btn {
            width: 100%;
        }

    }
</style>
